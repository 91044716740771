.buyerContainer{
    padding: 0%;
    width: 100%;
}
@media (min-width: 768px) {
    .pickupStyle{
      display: flex;
    }
    .invoiceDate{
        display: flex;
    }
    .buyerContainer{
        padding: 0%;
        width: 100%;
    }
}
@media (max-width : 425px){
    .resp-sku {
        text-align: left !important;
    }
}
